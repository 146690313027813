// Generated by Framer (808c984)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/Z4QJ2YpzpVnWRfR6Ccgg/Video.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["z35KvI2vh", "LIfvEw3fO", "S2ubUcoxs"];

const serializationHash = "framer-F9kIS"

const variantClassNames = {LIfvEw3fO: "framer-v-10layly", S2ubUcoxs: "framer-v-157klkf", z35KvI2vh: "framer-v-o769jw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Desktop 2k": "z35KvI2vh", "Variant 3": "S2ubUcoxs", Desktop: "LIfvEw3fO"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "z35KvI2vh"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, D3qBQ6Giv, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "z35KvI2vh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-o769jw", className, classNames)} data-framer-name={"Desktop 2k"} layoutDependency={layoutDependency} layoutId={"z35KvI2vh"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} {...addPropertyOverrides({LIfvEw3fO: {"data-framer-name": "Desktop"}, S2ubUcoxs: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-101o64t-container"} layoutDependency={layoutDependency} layoutId={"wJwH9z10p-container"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={12} bottomLeftRadius={12} bottomRightRadius={12} controls={false} height={"100%"} id={"wJwH9z10p"} isMixedBorderRadius={false} layoutId={"wJwH9z10p"} loop muted objectFit={"cover"} playing posterEnabled={false} srcFile={D3qBQ6Giv} srcType={"Upload"} srcUrl={"https://assets.mixkit.co/videos/preview/mixkit-shining-sun-in-the-sky-surrounded-by-moving-clouds-31793-small.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={12} topRightRadius={12} volume={25} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-F9kIS.framer-1s5noaa, .framer-F9kIS .framer-1s5noaa { display: block; }", ".framer-F9kIS.framer-o769jw { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1077px; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-F9kIS .framer-101o64t-container { flex: none; height: 1077px; position: relative; width: 828px; z-index: 0; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-F9kIS.framer-o769jw { gap: 0px; } .framer-F9kIS.framer-o769jw > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-F9kIS.framer-o769jw > :first-child { margin-left: 0px; } .framer-F9kIS.framer-o769jw > :last-child { margin-right: 0px; } }", ".framer-F9kIS.framer-v-10layly.framer-o769jw, .framer-F9kIS.framer-v-157klkf.framer-o769jw { height: 1000px; }", ".framer-F9kIS.framer-v-10layly .framer-101o64t-container, .framer-F9kIS.framer-v-157klkf .framer-101o64t-container { height: 1000px; width: 588px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1077
 * @framerIntrinsicWidth 828
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"LIfvEw3fO":{"layout":["auto","fixed"]},"S2ubUcoxs":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroopbT6gHz: React.ComponentType<Props> = withCSS(Component, css, "framer-F9kIS") as typeof Component;
export default FrameroopbT6gHz;

FrameroopbT6gHz.displayName = "Careers / Blured Video";

FrameroopbT6gHz.defaultProps = {height: 1077, width: 828};

addPropertyControls(FrameroopbT6gHz, {variant: {options: ["z35KvI2vh", "LIfvEw3fO", "S2ubUcoxs"], optionTitles: ["Desktop 2k", "Desktop", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FrameroopbT6gHz, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})